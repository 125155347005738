import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

export const SearchBox = styled(TextField)`
  background: white;
  color: #242333;
  border-radius: 100px;
  border: 0px;

  & .MuiOutlinedInput-root {
    border-radius: 100px;
    border: 0px;
    font-weight: 500;

    & input::placeHolder {
        color: #888888; 
        opacity: 100%;
        font-weight: 500;
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 0px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  }
`;

export default SearchBox;