import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material/styles';

export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#1D1761',
    },
    secondary: {
      main: '#02B28E',
      contrastText: '#FFFFFF'
    },
    white2: {
      main: '#FFFFFF',
      contrastText: '#1D1761'
    },
    grey2: {
      main: '#888888',
    },
    greyPrimary: {
      main: '#242333',
      contrastText: '#ffffff'
    },
    lightblue: {
      main: '#E3E8EF',
      contrastText: '#1D1761'
    },
    whiteNav: {
      main: '#FFFFFF',
      contrastText: '#242333'
    },
    lightPrimary: {
      main: '#E8E7EF',
      contrastText: '#1D1761'
    },
    lightSecondary: {
      main: '#E5F7F3',
      contrastText: '#02B28E'
    },
    skyBlue: {
      main: '#E5F4FF',
      contrastText: '#0090FF'
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      '"Sora"',
      '"Readex Pro"',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          borderColor: '#C7D2E1',
          fontSize: '14px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontSize: '14px',
          borderRadius: 10,
          textTransform: "none",
          boxShadow: '0 0 0 0'
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 1
      },
      variants: [
        {
          props: {
            variant: "outlined",
          },
          style: {
            borderRadius: 16,
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 3%), 0px 1px 3px 0px rgb(0 0 0 / 3%)'
          },
        },
      ]
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          backgroundColor: '#EEF2F8'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#242333'
        }
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        ListboxProps: {
          sx: { fontSize: '14px' }
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 920,
      lg: 1200,
      xl: 1536,
    },
  },
};
const theme = createTheme(themeOptions);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
