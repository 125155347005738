import './App.css';
import {
  Box,
  Button,
  IconButton,
  Typography,
  InputAdornment,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Tooltip,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Drawer
} from '@mui/material';
import {
  HambergerMenu,
  SearchNormal1,
  MouseCircle,
  RepeateMusic,
  Setting3,
  Tree,
  People,
  InfoCircle,
  TickCircle,
  ArrowSquareRight,
  Personalcard,
  CloseSquare,
} from 'iconsax-react';
import RoundButton from './components/round-btn';
import SearchBox from './components/search-box';
import RoundAccordion from './components/round-accordion';
import Logo from './logo.png';
// import LimitedOfferImg from './img/limitedOffer.png';
import { useState } from 'react';

function App() {
  const [value, setValue] = useState('');
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container = window !== undefined ? () => window.document.body : undefined;

  const handleNavigate = (id) => {
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset - 120;
    window.scrollTo({ top: y, behavior: 'smooth' });
    //element.scrollIntoView({ behavior: 'smooth' });
  };

  const search = () => {
    if (value) {
      window.location.replace('https://app.nsyncapp.net/find-people/' + value)
    }
  }

  return (
    <>
      <Box sx={{ maxWidth: '1200px', m: '0 auto', p: '0 20px' }}>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px  8px',
            width: '100%',
            position: 'fixed',
            background: 'white',
            top: 0,
            right: 0,
            zIndex: 20,
            overflow: 'hidden'
          }}
        >
          <img
            src={Logo}
            width={130}
            height={82}
            alt="logo"
            className="logo"
          />

          <Box
            className="menu-links"
            sx={{
              mt: 3,
              display: { lg: 'flex', md: 'block', sm: 'none', xs: 'none' },
            }}
          >
            <RoundButton onClick={() => handleNavigate('why-nsync')}>Why n&apos;Sync?</RoundButton>
            <RoundButton onClick={() => handleNavigate('how-it-works')} >How it Works</RoundButton>
            <RoundButton onClick={() => handleNavigate('pricing')}>Pricing</RoundButton>
            <RoundButton onClick={() => handleNavigate('faq')}>FAQ</RoundButton>
          </Box>

          <Box sx={{ mt: 3, display: { lg: 'none', md: 'none', sm: 'block' } }}>
            <RoundButton
              href="https://app.nsyncapp.net/login"
              sx={{ mr: 2 }}
              color="primary"
              variant="contained"
            >
              Login
            </RoundButton>
            <IconButton onClick={handleDrawerToggle}>
              <HambergerMenu size="32" />
            </IconButton>

            <Drawer
              anchor='right'
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              onClick={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '300px' },
              }}
            >
              <Box className='mobileMenuHeader'>
                <IconButton sx={{ ml: 2, mr: 1 }} onClick={handleDrawerToggle}>
                  <CloseSquare
                    size="36"
                    color='#242333'
                    variant='Bold'
                  />
                </IconButton>
                <Typography variant='h5' fontWeight='700'>Menu</Typography>
              </Box>

              <Button className='mobile-links' onClick={() => handleNavigate('why-nsync')}>
                Why n&apos;Sync?
              </Button>
              <Button className='mobile-links' onClick={() => handleNavigate('how-it-works')}>
                How it Works
              </Button>
              <Button className='mobile-links' onClick={() => handleNavigate('pricing')}>
                Pricing
              </Button>
              <Button className='mobile-links' onClick={() => handleNavigate('faq')}>
                FAQ
              </Button>
            </Drawer>
          </Box>

          <Box
            sx={{
              mt: 3,
              display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' },
            }}
          >
            <RoundButton
              href="https://app.nsyncapp.net/login"
              sx={{ mr: 2 }}
              variant="outlined"
            >
              Login
            </RoundButton>
            <RoundButton
              href="https://app.nsyncapp.net/register"
              sx={{ mr: 2 }}
              color="primary"
              variant="contained"
            >
              Register Free
            </RoundButton>
          </Box>
        </Box>

        {/* Hero */}
        <Grid container sx={{ mt: 12 }}>
          <Grid
            item
            xs={0}
            sm={0}
            md={0}
            lg={1}
            order={{ sm: 1, xs: 1, md: 2, lg: 2 }}
          />
          <Grid
            item
            xs={9}
            sm={5}
            md={5}
            lg={5}
            order={{ sm: 1, xs: 1, md: 2, lg: 2 }}
            className="hero-pic"
          >
            <img src="/img/hero.png" alt="Hero" />
          </Grid>

          <Grid
            item
            xs={12}
            sm={11}
            md={7}
            lg={6}
            sx={{ p: 2, m: '0 auto', mt: { md: 7 } }}
            order={{ sm: 2, xs: 2, md: 1, lg: 1 }}
          >
            <Typography fontSize={36} lineHeight={1.3} fontWeight={700}>
              Revolutionize Connections Management and Technology
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 3,
              }}
            >
              <Typography fontSize={16} color="grey" sx={{ mt: 2 }}>
                n&apos;Sync is a contact management platform designed to help you
                organize and grow your network
              </Typography>
              <Box
                sx={{
                  mt: 4,
                  ml: 3,
                  mr: 2,
                  display: { md: 'none', lg: 'block', sm: 'none', xs: 'none' },
                }}
              >
                <img src="/img/arrow.png" width={120} height={70} alt="arrow" />
              </Box>
            </Box>
            {/* Search box */}
            <Box sx={{ m: 2, mt: 7 }}>
              <SearchBox
                fullWidth
                placeholder="Find People..."
                value={value}
                onChange={(e) => setValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchNormal1 size="30" variant="Bulk" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <RoundButton onClick={search} variant="contained">Search</RoundButton>
                    </InputAdornment>
                  ),
                }}
                helperText={
                  <span
                    style={{
                      display: 'block',
                      fontSize: 13,
                      marginTop: '16px',
                      color: 'black',
                      fontWeight: 450,
                    }}
                  >
                    More than <b>200,000 contacts</b>
                  </span>
                }
              />
            </Box>
          </Grid>
        </Grid>

        {/* Why n'Sync? */}
        <Box
          textAlign={{ xs: 'center', sm: 'left', lg: 'left', md: 'left' }}
          sx={{ pt: 8, mb: 2 }}

        >
          <span className='anchor' id='why-nsync'></span>
          <Typography
            color="secondary"
            fontSize={36}
            lineHeight={1.3}
            fontWeight={700}
            sx={{ mb: 4 }}
            textAlign="center"
          >
            Why n&apos;Sync?
          </Typography>

          <Grid container spacing={1}>
            <Grid lg={4} md={6} sm={6} item sx={{ mt: 2, mb: 3 }}>
              <MouseCircle variant="Bulk" size={42} />
              <Typography fontWeight={500} color="primary" sx={{ mb: 1, mt: 1 }}>
                Easy To Use
              </Typography>
              <Typography color="grey" lineHeight={1.8}>
                n'Sync offers a straightforward and user-friendly contact management platform, making it simple to organize and grow your network.
              </Typography>
            </Grid>

            <Grid lg={4} md={6} sm={6} item sx={{ mt: 2, mb: 3 }}>
              <RepeateMusic variant="Bulk" size={42} />
              <Typography fontWeight={500} color="primary" sx={{ mb: 1, mt: 1 }}>
                Stay Connected and Up-to-Date
              </Typography>
              <Typography color="grey" lineHeight={1.8}>
                With n'Sync, you can easily stay in touch with your contacts and ensure you always have the latest information at your fingertips.
              </Typography>
            </Grid>

            <Grid lg={4} md={6} sm={6} item sx={{ mt: 2, mb: 3, width: '100%' }}>
              <Tree variant="Bulk" size={42} />
              <Typography fontWeight={500} color="primary" sx={{ mb: 1, mt: 1 }}>
                Sustainable Solution
              </Typography>
              <Typography color="grey" lineHeight={1.8}>
                n'Sync provides a sustainable and long-lasting solution to manage your contacts, no more physical bussiness cards.
              </Typography>
            </Grid>

            <Grid lg={4} md={6} sm={6} item sx={{ mt: 2, mb: 3 }}>
              <Setting3 variant="Bulk" size={42} />
              <Typography fontWeight={500} color="primary" sx={{ mb: 1, mt: 1 }}>
                Control Access
              </Typography>
              <Typography color="grey" lineHeight={1.8}>
                Maintain control over who can access your contact information, ensuring your privacy and security.
              </Typography>
            </Grid>
            <Grid lg={4} md={6} sm={6} item sx={{ mt: 2, mb: 3, width: '100%' }}>
              <People variant="Bulk" size={42} />
              <Typography fontWeight={500} color="primary" sx={{ mb: 1, mt: 1 }}>
                Expand your connections
              </Typography>
              <Typography sx={{ width: '100%' }} color="grey" lineHeight={1.8}>
                Use n'Sync to effortlessly expand your network by adding and managing new contacts with ease.
              </Typography>
            </Grid>
            <Grid lg={4} md={6} sm={6} item sx={{ mt: 2, mb: 3, width: '100%' }}>
              <Personalcard variant="Bulk" size={42} />
              <Typography fontWeight={500} color="primary" sx={{ mb: 1, mt: 1 }}>
                Get listed!
              </Typography>
              <Typography color="grey" lineHeight={1.8}>
                Showcase your profile and contact details, making it easy for others to find and connect with you.
              </Typography>
            </Grid>

          </Grid>
        </Box>

        {/* How it works? */}
        <Box textAlign="center" sx={{ pt: 8, mb: 6 }}>
          <span className='anchor' id='how-it-works'></span>
          <Typography
            color="secondary"
            fontSize={36}
            lineHeight={1.3}
            fontWeight={700}
            sx={{ mb: 4 }}
          >
            How it works?
          </Typography>

          <Grid container className="how-conatiner">
            <Grid item lg={4} md={4} sm={6} xs={9}>
              <img src="/img/1.png" alt="step 1" />
              <Typography
                color="#E3E8EF"
                textAlign="left"
                variant="h1"
                fontWeight={700}
                sx={{ float: 'left' }}
              >
                1
              </Typography>
              <Typography
                textAlign="left"
                marginTop={5}
                fontWeight={500}
                fontSize={18}
              >
                Enter your data
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={9}>
              <img src="/img/2.png" alt="step 2" />
              <Typography
                color="#E3E8EF"
                textAlign="left"
                variant="h1"
                fontWeight={700}
                sx={{ float: 'left' }}
              >
                2
              </Typography>
              <Typography
                textAlign="left"
                marginTop={5}
                fontWeight={500}
                fontSize={18}
              >
                Create your cards
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={9}>
              <img src="/img/3.png" alt="step 3" />
              <Typography
                color="#E3E8EF"
                textAlign="left"
                variant="h1"
                fontWeight={700}
                sx={{ float: 'left' }}
              >
                3
              </Typography>
              <Typography
                textAlign="left"
                marginTop={5}
                fontWeight={500}
                fontSize={18}
              >
                Start Syncing!
              </Typography>
            </Grid>
          </Grid>
        </Box>

      </Box>
      {/* Pricing */}
      <Box
        textAlign="center"
        sx={{ mt: 8, mb: 2 }}
        className="pricing-section"
      >
        <span className='anchor' id='pricing'></span>
        <Typography fontSize={36} lineHeight={1.3} fontWeight={700} sx={{ pt: 3 }}>
          Pricing
        </Typography>
        <Typography sx={{ mb: 3 }} color="#888888">
          Lifetime Membership
        </Typography>

        <Grid container sx={{ mt: 6, justifyContent: 'center' }} spacing={2}>
          {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ mt: -14, textAlign: 'right' }}>
            <img src={LimitedOfferImg} width={380} height={190} alt='limited offer' />
          </Grid> */}
          {memberships.map(membership =>
            <Grid key={membership._id} item lg={3} md={6} sm={6} xs={12}>
              <Paper
                elevation={0}
                sx={{ p: 3, width: '80%', borderRadius: 2 }}
                className='paper'
              >
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography variant="h6" fontWeight={600}>
                    {membership.name}
                  </Typography>
                  <Tooltip title={membership.info}>
                    <InfoCircle
                      size="32"
                      variant="Bulk"
                      style={{ transform: 'rotate(-180deg)' }}
                    />
                  </Tooltip>
                </Box>
                <Typography color="#888" fontSize={14} textAlign='left'>
                  {membership.title}
                </Typography>
                <Typography fontWeight={500} sx={{ mb: 1, mt: 2, textDecoration: 'line-through' }}>
                  {membership.oldPrice === 0.0
                    ? ''
                    : '$' + membership.oldPrice}{' '}
                </Typography>
                <Typography fontWeight={500} fontSize={22} sx={{ mt: 1 }}>
                  {membership.price === 0.0
                    ? 'Free'
                    : '$' + membership.price}{' '}
                </Typography>
                <Typography sx={{ mb: 2 }} fontSize={12}>lifetime</Typography>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ height: 'fit-content' }}
                  href="https://app.nsyncapp.net/register"
                >
                  Getting Started
                </Button>

                <Typography
                  fontWeight={500}
                  sx={{ m: '16px 0' }}
                  fontSize={14}
                >
                  {membership.name} membership includes
                </Typography>
                <List sx={{ mt: 2 }}>
                  {membership.features.map(item =>
                    <ListItem key={item} disablePadding>
                      <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                        <TickCircle
                          size="24"
                          variant="Bulk"
                          color="#02B28E"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: 14 }}
                        primary={item}
                      />
                    </ListItem>
                  )}
                </List>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>


      {/* FAQ */}
      <Box textAlign="center" sx={{ mb: 2, p: 2, pt: 12 }}>
        <span className='anchor' id='faq'></span>
        <Typography
          fontSize={24}
          lineHeight={1.3}
          fontWeight={700}
          sx={{ mb: 5 }}
        >
          Frequently Asked Questions
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ m: '0 auto', mb: 2, borderRadius: '16px', overflow: 'hidden', maxWidth: '800px' }}>
            <RoundAccordion elevation={0}>
              <AccordionSummary sx={{ borderRadius: 16 }}>
                <Typography fontWeight={500}>
                  What are the major gaps that nSync fill?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography textAlign="left" fontSize={14} color="#888888">
                  Contact Info Exchange & Storage - Outdated Information Lack
                  of - Privacy Language Barrier - Non-Centralized Database
                </Typography>
              </AccordionDetails>
            </RoundAccordion>
          </Box>

          <Box sx={{ m: '0 auto', mb: 2, borderRadius: '16px', overflow: 'hidden', maxWidth: '800px' }}>
            <RoundAccordion elevation={0}>
              <AccordionSummary>
                <Typography fontWeight={500}>
                  How can I create my first digital card?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography textAlign="left" fontSize={14} color="#888888">
                  You can make a free digital business card with the nSync
                  app. After downloading the free app on iOS, Android, or the
                  web, you can create, customize, and share your first card.
                </Typography>
              </AccordionDetails>
            </RoundAccordion>
          </Box>

          <Box sx={{ m: '0 auto', mb: 2, borderRadius: '16px', overflow: 'hidden', maxWidth: '800px' }}>
            <RoundAccordion elevation={0}>
              <AccordionSummary>
                <Typography fontWeight={500}>
                  What is a digital business card?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography textAlign="left" fontSize={14} color="#888888">
                  Digital business cards are used by both individuals and
                  businesses to quickly and sustainably exchange contact
                  information. They’re more engaging, cost-effective, and
                  eco-friendly than traditional physical business cards.
                  Digital cards are also known as virtual, electronic, and—in
                  some cases—NFC business cards.
                </Typography>
              </AccordionDetails>
            </RoundAccordion>
          </Box>
          <Box sx={{ m: '0 auto', mb: 2, borderRadius: '16px', overflow: 'hidden', maxWidth: '800px' }}>
            <RoundAccordion elevation={0}>
              <AccordionSummary>
                <Typography fontWeight={500}>Is nSync app free?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography textAlign="left" fontSize={14} color="#888888">
                  You can create a basic card completely free, sign up here.
                  More information about premium plans is available on
                  n&apos;Sync Pricing page.
                </Typography>
              </AccordionDetails>
            </RoundAccordion>
          </Box>
        </Box>
      </Box>

      <Box className='footer'>
        <Box className='footer-action'>
          <Typography fontSize={24} fontWeight={600}>
            Ready to create Your first card and sync your contacts
          </Typography>
          <RoundButton
            variant='contained'
            size='large'
            color='whiteNav'
            sx={{ height: 'fit-content' }}
            endIcon={<ArrowSquareRight variant='Bulk' style={{ marginLeft: '16px' }} />}
            href="https://app.nsyncapp.net/register"
          >
            Free Registration
          </RoundButton>
        </Box>


        <Grid container sx={{ maxWidth: '1200px', margin: '20px auto', padding: 3 }}>
          <Grid item lg={5}>
            <img
              src={Logo}
              width={130}
              height={82}
              alt="logo"
              className="logo"
            />
            <Typography fontSize={14} color='#888' sx={{ mt: 2, mb: 2 }}>
              n'Sync is an easy-to-use contact management platform that simplifies organizing and expanding your network. Its user-friendly interface allows you to effortlessly add, update, and manage contacts. Stay organized and connected with n'Sync!
            </Typography>
            <Typography fontSize={14} fontWeight={500}>
              All rights are reserved nS'ync App © 2023
            </Typography>
          </Grid>
          <Grid item lg={1}></Grid>
          <Grid item lg={6} sx={{ display: 'flex', justifyContent: 'space-between', mt: 5 }}>
            <List>
              <ListItem>
                <Typography fontSize={14} fontWeight={500} color='secondary' sx={{ mb: 2 }}>
                  n'Sync pages
                </Typography>
              </ListItem>
              <ListItem>
                <Link href='https://app.nsyncapp.net/terms-of-use' color='#000'>
                  Terms of Use
                </Link>
              </ListItem>
              <ListItem>
                <Link href='https://app.nsyncapp.net/terms-of-use' color='#000'>
                  Privacy Policy
                </Link>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <Typography fontSize={14} fontWeight={500} color='secondary' sx={{ mb: 2 }}>
                  Company
                </Typography>
              </ListItem>
              <ListItem>
                <Link href='#' color='#000'>
                  About Us
                </Link>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <Typography fontSize={14} fontWeight={500} color='secondary' sx={{ mb: 2 }}>
                  Quick Contact
                </Typography>
              </ListItem>
              <ListItem>
                <Link href='mailto:info@nsyncapp.net' color='#000'>
                  info@nsyncapp.net
                </Link>
              </ListItem>
            </List>
          </Grid>
        </Grid>

      </Box>



    </>
  );
}

export const memberships = [
  {
    _id: '1',
    name: 'Basic',
    title: 'Starter / Personal',
    price: 0.0,
    oldPrice: 0.0,
    features: [
      'Unlimeted connections',
      '1 Basic Card',
      '1 Information Language',
      'Publish Basic Card for free',
      'Rewards Program',
    ],
    info:
      "Share your basic information with your family & friends and keep them n' Sync with our starter package for basic personal use.",
  },
  {
    _id: '2',
    name: 'Standard',
    title: 'Personal / Socializer',
    price: 0.0,
    oldPrice: 4.99,
    features: [
      'Ads free',
      'Unlimeted connections',
      '1 Basic Card',
      '1 Standard  Card',
      '2 Information Language',
      'Publish Basic Card for free',
      'Rewards Program - Higher multipler',
    ],
    info:
      'Grow up your social network with our advanced package for personal use Which allow you to share more personal and social accounts information.',
  },
  {
    _id: '3',
    name: 'Premium',
    title: 'Socializer / Professional',
    price: 3.99,
    oldPrice: 9.99,
    features: [
      'Ads free',
      'Unlimeted connections',
      '1 Basic Card',
      '2 Standard  Cards',
      '2 Information Languages',
      'Publish Basic Card for free',
      'Rewards Program - Highest multipler',
    ],
    info:
      'Build a strong professional & social connections with your clients, using our premium package and be distinguished among your peers.',
  },
];

export default App;
